import React from 'react';
import styled from 'styled-components/macro';

import HowToHelpBackgroundDesktop from '../../assets/img/business/howToHelpBackgroundDesktop.png';

import TitleHeaderText from '../../components/TitleHeaderText';
import ProfileCard from '../../components/ProfileCardV2/index';
import { HowWeCanHelpData } from './const/howWeCanHelp';

const MainDiv = styled.div`
  background-image: url(${HowToHelpBackgroundDesktop});
  box-sizing: border-box;
  background-size: cover;
  z-index: -1;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
  padding-bottom: 10rem;
  margin-top: 2rem;
  top: -1px;
  background-position: center top;
  text-align: center;
  @media (max-width: 760px) {
    background-image: none;
    padding-top: 0;
    padding-bottom: 0;
  }
`;

function HowWeCanHelp() {
  return (
    <MainDiv>
      <TitleHeaderText text={'Try a new job everyday'} />
      <div style={{ width: '70%', margin: 'auto' }}>
        <ProfileCard
          cardWidth={'250px'}
          cardHight={'132px'}
          disableScroll
          data={HowWeCanHelpData}
          linkText={'See All Jobs'}
        />
      </div>
    </MainDiv>
  );
}

export default HowWeCanHelp;
