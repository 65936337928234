import React from 'react';
import styled from 'styled-components/macro';

import fontSize from '../../../assets/fontSize';

const Card = styled.div`
  background: #ffd601;
  padding: 28px 40px;
  border-radius: 20px 20px 14px 14px;
`;
const Container = styled.div`
  display: flex;
  align-items: center;
  width: 210px;
  @media (max-width: 920px) {
    width: 258px;
    margin-bottom: 8px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;
const H2 = styled.h2`
  text-align: center;
  margin-top: 0;
`;
const Text = styled.p`
  font-size: ${fontSize.xLarge};
  font-weight: normal;
  line-height: 22px;
`;
const ItemsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex: 1;

  @media (max-width: 665px) {
    justify-content: center;
  }
`;

const GetStarted = props => {
  const { data, title } = props;
  return (
    <Card>
      <H2>{title}</H2>
      <ItemsWrapper>
        {data.map((item, key) => {
          return (
            <Container key={key}>
              {item.icon}
              <Text>{item.text}</Text>
            </Container>
          );
        })}
      </ItemsWrapper>
    </Card>
  );
};

export default GetStarted;
