/* eslint-disable */
import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const WarehouseHelper =
  '../../../assets/img/business/members/experience/warehouse-helper.png';
const Promoter = '../../../assets/img/business/members/experience/promoter.png';
const PersonalHelperCleaner =
  '../../../assets/img/business/members/experience/personal-helper-cleaner.png';
const ManningBooth =
  '../../../assets/img/business/members/experience/manning-booth.png';
const Labour = '../../../assets/img/business/members/experience/labour.png';
const FoodShopping =
  '../../../assets/img/business/members/experience/food-shopping.png';
const Dispatch = '../../../assets/img/business/members/experience/disptach.png';
const EventStaff =
  '../../../assets/img/business/members/experience/event-staff.png';
const DataEntry =
  '../../../assets/img/business/members/experience/data-entry.png';
const Caller = '../../../assets/img/business/members/experience/caller.png';
const Bulky = '../../../assets/img/business/members/experience/bulky.png';
const Flyering = '../../../assets/img/business/members/experience/flyering.png';
const MerchantAcquisition =
  '../../../assets/img/business/members/experience/merchant-acquisition.png';
const SalesAdmin =
  '../../../assets/img/business/members/experience/sales_admin.png';
const AdminStaff =
  '../../../assets/img/business/members/experience/admin-staff.png';
const ChequeDeposit =
  '../../../assets/img/business/members/experience/cheque_deposit.png';
const CustomerService =
  '../../../assets/img/business/members/experience/customer-service.png';
const Stamping = '../../../assets/img/business/members/experience/stamping.png';
const DeliverDocument =
  '../../../assets/img/business/members/experience/deliver-document.png';
const Usher = '../../../assets/img/business/members/experience/usher.png';
const BoothSetup =
  '../../../assets/img/business/members/experience/booth_setup.png';
const CateringHelp =
  '../../../assets/img/business/members/experience/catering-help.png';
const FloorStaff =
  '../../../assets/img/business/members/experience/floor-staff.png';
const RetailStaff =
  '../../../assets/img/business/members/experience/retail-staff.png';
const PetCare = '../../../assets/img/petCare.svg';

const AdminLogo = '../../../assets/img/business/how-we-can-help/admin-logo.png';
const AdminHeader =
  '../../../assets/img/business/how-we-can-help/admin-header.png';
const LogisticLogo =
  '../../../assets/img/business/how-we-can-help/logistic-logo.png';
const LogisticHeader =
  '../../../assets/img/business/how-we-can-help/logistic-header.png';
const MarketingLogo =
  '../../../assets/img/business/how-we-can-help/marketing-logo.png';
const MarketingHeader =
  '../../../assets/img/business/how-we-can-help/marketing-header.png';
const OperationsLogo =
  '../../../assets/img/business/how-we-can-help/operations-logo.png';
const OperationsHeader =
  '../../../assets/img/business/how-we-can-help/operations-header.png';

const HomeLogo = '../../../assets/img/gogetters/newJobEveryday/homeIcon.png';
const HomeHeader = '../../../assets/img/gogetters/newJobEveryday/home.jpg';
const LifeLogo = '../../../assets/img/gogetters/newJobEveryday/LifeIcon.png';
const LifeHeader = '../../../assets/img/gogetters/newJobEveryday/life.jpg';

export const HowWeCanHelpData = [
  {
    banner: (
      <StaticImage
        src={LogisticHeader}
        placeholder="blurred"
        style={{ height: '100%', borderRadius: '10px 10px 0 0' }}
        alt="Logistics"
      />
    ),
    logo: (
      <StaticImage
        src={LogisticLogo}
        width={40}
        placeholder="blurred"
        alt="Logistics"
      />
    ),
    name: 'Logistics',
    description:
      'Help to pick and pack at warehouses, dispatch and become event staff.',
    collapsItems: [
      [
        <StaticImage
          src={WarehouseHelper}
          placeholder="blurred"
          style={{ width: '100%', height: '100%' }}
          alt="Picker and Packer"
        />,
        'Packer',
        '/jobs/picker-and-packer'
      ],
      [
        <StaticImage
          src={Dispatch}
          placeholder="blurred"
          style={{ width: '100%', height: '100%' }}
          alt="Dispatch"
        />,
        'Dispatch',
        'javascript:void(0);'
      ],
      [
        <StaticImage
          src={Bulky}
          placeholder="blurred"
          style={{ width: '100%', height: '100%' }}
          alt="General Labour"
        />,
        'General Labour',
        '/jobs/general-labour'
      ],
      [
        <StaticImage
          src={Labour}
          placeholder="blurred"
          style={{ width: '100%', height: '100%' }}
          alt="Moving Bulky Items"
        />,
        'Moving Bulky Items',
        '/jobs/bulky'
      ],
      [
        <StaticImage
          src={EventStaff}
          placeholder="blurred"
          style={{ width: '100%', height: '100%' }}
          alt="Event Staff"
        />,
        'Event Staff',
        '/jobs/event-crew'
      ],
      [
        <StaticImage
          src={WarehouseHelper}
          placeholder="blurred"
          style={{ width: '100%', height: '100%' }}
          alt="Warehouse Worker"
        />,
        'Warehouse Worker',
        '/jobs/warehouse-worker'
      ]
    ]
  },
  {
    banner: (
      <StaticImage
        src={MarketingHeader}
        placeholder="blurred"
        style={{ height: '100%', borderRadius: '10px 10px 0 0' }}
        alt="Sales / Marketing"
      />
    ),
    logo: (
      <StaticImage
        src={MarketingLogo}
        width={40}
        placeholder="blurred"
        alt="Sales / Marketing"
      />
    ),
    name: 'Sales / Marketing',
    description:
      'Become professional promoters, do flyering and do sales calls.',
    collapsItems: [
      [
        <StaticImage
          src={Promoter}
          placeholder="blurred"
          style={{ width: '100%', height: '100%' }}
          alt="Promoter"
        />,
        'Promoter',
        '/jobs/promoter'
      ],
      [
        <StaticImage
          src={Flyering}
          placeholder="blurred"
          style={{ width: '100%', height: '100%' }}
          alt="Flyering"
        />,
        'Flyering',
        '/jobs/flyering'
      ],
      [
        <StaticImage
          src={Caller}
          placeholder="blurred"
          style={{ width: '100%', height: '100%' }}
          alt="Caller"
        />,
        'Caller',
        '/jobs/caller'
      ],
      [
        <StaticImage
          src={SalesAdmin}
          placeholder="blurred"
          style={{ width: '100%', height: '100%' }}
          alt="Sales Admin"
        />,
        'Sales Admin',
        '/jobs/sales-admin'
      ],
      [
        <StaticImage
          src={MerchantAcquisition}
          placeholder="blurred"
          style={{ width: '100%', height: '100%' }}
          alt="Salesperson"
        />,
        'Salesperson',
        '/jobs/salesperson'
      ]
    ]
  },
  {
    banner: (
      <StaticImage
        src={AdminHeader}
        placeholder="blurred"
        style={{ height: '100%', borderRadius: '10px 10px 0 0' }}
        alt="Admin"
      />
    ),
    logo: (
      <StaticImage
        src={AdminLogo}
        width={40}
        placeholder="blurred"
        alt="Admin"
      />
    ),
    name: 'Admin',
    description:
      'Help businesses do data entry, stamping at public offices and bank in cheques.',
    collapsItems: [
      [
        <StaticImage
          src={ChequeDeposit}
          placeholder="blurred"
          style={{ width: '100%', height: '100%' }}
          alt="Cheque Deposit"
        />,
        'Cheque Deposit',
        'javascript:void(0);'
      ],
      [
        <StaticImage
          src={DataEntry}
          placeholder="blurred"
          style={{ width: '100%', height: '100%' }}
          alt="Data Entry"
        />,
        'Data Entry',
        '/jobs/data-entry'
      ],
      [
        <StaticImage
          src={Stamping}
          placeholder="blurred"
          style={{ width: '100%', height: '100%' }}
          alt="Stamping"
        />,
        'Stamping',
        '/jobs/stamping'
      ],
      [
        <StaticImage
          src={AdminStaff}
          placeholder="blurred"
          style={{ width: '100%', height: '100%' }}
          alt="Admin Staff"
        />,
        'Admin Staff',
        '/jobs/admin-staff'
      ],
      [
        <StaticImage
          src={DeliverDocument}
          placeholder="blurred"
          style={{ width: '100%', height: '100%' }}
          alt="Deliver Document"
        />,
        'Deliver Document',
        'javascript:void(0);'
      ],
      [
        <StaticImage
          src={CustomerService}
          placeholder="blurred"
          style={{ width: '100%', height: '100%' }}
          alt="Customer Service"
        />,
        'Customer Service',
        '/jobs/customer-service'
      ]
    ]
  },
  {
    banner: (
      <StaticImage
        src={OperationsHeader}
        placeholder="blurred"
        style={{ height: '100%', borderRadius: '10px 10px 0 0' }}
        alt="Operations"
      />
    ),
    logo: (
      <StaticImage
        src={OperationsLogo}
        width={40}
        placeholder="blurred"
        alt="Logistics"
      />
    ),
    name: 'Operations',
    description:
      'Transform into a restaurant helper, retail staff or help to set up and work at booths.',
    collapsItems: [
      [
        <StaticImage
          src={RetailStaff}
          placeholder="blurred"
          style={{ width: '100%', height: '100%' }}
          alt="Retail Staff"
        />,
        'Retail Staff',
        '/jobs/retail-staff'
      ],
      [
        <StaticImage
          src={FloorStaff}
          placeholder="blurred"
          style={{ width: '100%', height: '100%' }}
          alt="Floor Staff"
        />,
        'Floor Staff',
        '/jobs/floor-staff'
      ],
      [
        <StaticImage
          src={Usher}
          placeholder="blurred"
          style={{ width: '100%', height: '100%' }}
          alt="Usher"
        />,
        'Usher',
        '/jobs/usher'
      ],
      [
        <StaticImage
          src={CateringHelp}
          placeholder="blurred"
          style={{ width: '100%', height: '100%' }}
          alt="Catering Helper"
        />,
        'Catering Helper',
        '/jobs/catering-helper'
      ],
      [
        <StaticImage
          src={ManningBooth}
          placeholder="blurred"
          style={{ width: '100%', height: '100%' }}
          alt="Manning Booth"
        />,
        'Manning Booth',
        '/jobs/manning-booth'
      ],
      [
        <StaticImage
          src={BoothSetup}
          placeholder="blurred"
          style={{ width: '100%', height: '100%' }}
          alt="Booth Setup"
        />,
        'Booth Set Up',
        'javascript:void(0);'
      ],
      [
        <StaticImage
          src={Promoter}
          placeholder="blurred"
          style={{ width: '100%', height: '100%' }}
          alt="Promoter"
        />,
        'Florist Assistant',
        '/jobs/florist-assistant'
      ],
      [
        <StaticImage
          src={FloorStaff}
          placeholder="blurred"
          style={{ width: '100%', height: '100%' }}
          alt="Kitchen Helper"
        />,
        'Kitchen Helper',
        '/jobs/kitchen-helper'
      ],
      [
        <StaticImage
          src={CateringHelp}
          placeholder="blurred"
          style={{ width: '100%', height: '100%' }}
          alt="Waiter or Server"
        />,
        'Waiter or Server',
        '/jobs/waiter-server'
      ]
    ]
  },
  {
    banner: (
      <StaticImage
        src={HomeHeader}
        placeholder="blurred"
        style={{ height: '100%', borderRadius: '10px 10px 0 0' }}
        alt="Home"
      />
    ),
    logo: (
      <StaticImage src={HomeLogo} width={40} placeholder="blurred" alt="Home" />
    ),
    name: 'Home',
    description:
      'Help to arrange furniture, do household chores, and take care of pets.',
    collapsItems: [
      [
        <StaticImage
          src={Labour}
          placeholder="blurred"
          style={{ width: '100%', height: '100%' }}
          alt="Pack / Move"
        />,
        'Pack / Move',
        '/jobs/pick-and-move',
        'Help people move in or out, arrange furniture, pack and unpack.'
      ],
      [
        <StaticImage
          src={PersonalHelperCleaner}
          placeholder="blurred"
          style={{ width: '100%', height: '100%' }}
          alt="Household Chores"
        />,
        'Household Chores',
        '/jobs/household-chores',
        'Help with home repairs, gardening, cooking and cleaning.'
      ],
      [
        <StaticImage
          src={PetCare}
          placeholder="blurred"
          style={{ width: '100%', height: '100%' }}
          alt="Pet Care"
        />,
        'Pet Care',
        '/jobs/pet-care',
        'Walk your dog, feed your pet and vet visits.'
      ]
    ]
  },
  {
    banner: (
      <StaticImage
        src={LifeHeader}
        placeholder="blurred"
        style={{ height: '100%', borderRadius: '10px 10px 0 0' }}
        alt="Life"
      />
    ),
    logo: (
      <StaticImage src={LifeLogo} width={40} placeholder="blurred" alt="Life" />
    ),
    name: 'Life',
    description:
      'Deliver, shop for items, plan surprises and provide work assistance.',
    collapsItems: [
      [
        <StaticImage
          src={Dispatch}
          placeholder="blurred"
          style={{ width: '100%', height: '100%' }}
          alt="Dispatch"
        />,
        'Dispatch',
        'javascript:void(0);',
        'Help deliver almost anything.'
      ],
      [
        <StaticImage
          src={FoodShopping}
          placeholder="blurred"
          style={{ width: '100%', height: '100%' }}
          alt="Food/Shopping"
        />,
        'Food/Shopping',
        'javascript:void(0);',
        'Help buy, pay and deliver.'
      ],
      [
        <StaticImage
          src={EventStaff}
          placeholder="blurred"
          style={{ width: '100%', height: '100%' }}
          alt="Plan"
        />,
        'Plan',
        '/jobs/plan',
        'Help plan birthdays, events and surprises.'
      ],
      [
        <StaticImage
          src={DataEntry}
          placeholder="blurred"
          style={{ width: '100%', height: '100%' }}
          alt="Personal Assistant"
        />,
        'Personal Assistant',
        '/jobs/personal-assistant',
        'Help with stamping, research, data entry and banking cheques.'
      ]
    ]
  }
];
/* eslint-enable */
