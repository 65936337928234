import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import { StaticImage } from 'gatsby-plugin-image';
import Layout from '../components/Layout';

import MobileFAQBackground from '../assets/img/gogetters/MobileFAQBackground.png';
import MobileHereHowWeCanHelpBackground from '../assets/img/gogetters/MobileHereHowWeCanHelpBackground.png';
import MobileHowItWorksBackground from '../assets/img/gogetters/MobileHowItWorksBackground.png';
import DesktopFAQBackground from '../assets/img/gogetters/DesktopFAQBackground.png';
import DesktopHowItWorkBackground from '../assets/img/gogetters/DesktopHowItWorkBackground.png';

import TopHeader from '../views/shared/TopHeaderV2';
import AdvantagesOfGoget from '../views/shared/AdvantagesOfGogetV2';

import JobsProgress from '../components/homeAndLife/jobsInProgress/JobsProgress';

import TitleHeaderText from '../components/TitleHeaderText';

import HowItWorks from '../views/shared/HowItWorksV2';
import MeetOurCommunity from '../views/BusinessPage/MeetOurCommunity';
import BusinessGetStarted from '../views/gogettersPage/BusinessGetStarted';
import Banner from '../views/shared/Banner';
import FAQSection from '../views/shared/FAQ';
import DownloadUs from '../views/shared/DownloadUs';
import HeaderSeo from '../components/HeaderSeo';

import HowWeCanHelp from '../views/gogettersPage/HowWeCanHelp';
import { FaqData } from '../views/gogettersPage/const/FaqData';
import { howItWorksData } from '../views/gogettersPage/const/howItWorks';
import { meetCommunityData } from '../views/gogettersPage/const/meetOurCommunity';

import { SharedBackground } from '../assets/sharedStyle';

import MetaImg from '../assets/img/meta/gogetters.png';
import { graphql } from 'gatsby';

const Merchandise = '../assets/img/merchandise.svg';
const GogettersBanner = '../assets/img/gogetters/banner-desktop.jpg';
const GogettersBannerMobile = '../assets/img/gogetters/Mobile-Banner.jpg';

const DownloadUSGG = '../assets/img/DownloadUSGG.png';

const ChooseJobsYouLike =
  '../assets/img/gogetters/advantagesOfGoGet/ChooseJobsYouLike.png';
const GetPaidOnASafePlatform =
  '../assets/img/gogetters/advantagesOfGoGet/GetPaidOnASafePlatform.png';
const LearnNewSkills =
  '../assets/img/gogetters/advantagesOfGoGet/LearnNewSkills.png';
const WorkWithBigBrands =
  '../assets/img/gogetters/advantagesOfGoGet/WorkWithBigBrands.png';

const DesktopAdImage = '../assets/img/gogetters/adDesktop.jpg';
const MobileBannerImage = '../assets/img/gogetters/adMobile.jpg';

const FooterBackground = styled.div`
  background-image: url(${DesktopFAQBackground});
  ${SharedBackground};
  @media (max-width: 760px) {
    background-image: url(${MobileFAQBackground});
  }
`;

const HowItWorksBackgroundWrapper = styled.div`
  background-image: url(${DesktopHowItWorkBackground});
  padding-top: 1rem;
  padding-bottom: 4rem;
  ${SharedBackground};
  @media (max-width: 760px) {
    background-image: url(${MobileHowItWorksBackground});
    padding-bottom: 8rem;
  }
`;
const HowToHelpWrapper = styled.div`
  background-image: none;
  ${SharedBackground};
  @media (max-width: 760px) {
    background-image: url(${MobileHereHowWeCanHelpBackground});
    padding-top: 1rem;
    padding-bottom: 8rem;
  }
`;
const JobInProgressWrapper = styled.div`
  @media (max-width: 760px) {
    margin-top: 7rem;
  }
`;

const Gogetters = props => {
  const {
    data: { datoCmsGogetter }
  } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const advantagesOfGoget = [
    {
      img: (
        <StaticImage
          src={ChooseJobsYouLike}
          placeholder="blurred"
          alt="Choose jobs you like"
        />
      ),
      header: 'Choose jobs you like',
      desc: 'Filter for the type of jobs that you want to do.'
    },
    {
      img: (
        <StaticImage
          src={WorkWithBigBrands}
          placeholder="blurred"
          alt="Choose jobs you like"
        />
      ),
      header: 'Work with big brands',
      desc: (
        <div>
          Get opportunities in <br /> various industries.
        </div>
      )
    },
    {
      img: (
        <StaticImage
          src={LearnNewSkills}
          placeholder="blurred"
          alt="Choose jobs you like"
        />
      ),
      header: 'Learn new skills',
      desc: <div>We offer training to help you gain more experiences.</div>
    },
    {
      img: (
        <StaticImage
          src={GetPaidOnASafePlatform}
          placeholder="blurred"
          alt="Choose jobs you like"
        />
      ),
      header: 'Get paid on a safe platform',
      desc: (
        <div>
          Stay protected and <br /> get paid on time.
        </div>
      )
    }
  ];
  const gogettersBannerData = {
    title: 'Earn in your free time',
    subTitle:
      'Find work when you want, learn new skills, network with our community and stay protected.',
    buttonText: 'Get Started'
  };

  const appleLink =
    'https://apps.apple.com/my/app/gogetter-part-timer-community/id1483160371';
  const googleLink =
    'https://play.google.com/store/apps/details?id=my.goget.gogetter&hl=en';

  return (
    <>
      <HeaderSeo
        title="GoGet | Find Work and Earn in your Free Time"
        description="Work and earn in your free time while staying protected. Learn new skills and meet new people."
        image={MetaImg}
        keywords="gogetter signup, part time job, freelance job malaysia, temporary job, jawatan kosong part time"
        pathname={props.location.pathname}
        schemaMarkup={datoCmsGogetter.schemaMarkup}
      />
      <Layout
        showHeaderButtons
        footerAppData={{
          title: 'Flexible Jobs',
          subtitle: 'Earn in your free time',
          textButon: 'FIND JOBS',
          link: '/jobs',
          onClick: () => {
            window.analytics.track('find_jobs', {
              category: 'clickthrough',
              label: 'anchor_banner',
              platform: 'static',
              destination: '/jobs'
            });
          }
        }}
        hideDownloadPoster
        isShowingFooterAppBar
      >
        <TopHeader
          color={'black'}
          backgroundImg={
            <StaticImage
              src={GogettersBanner}
              loading="eager"
              style={{ height: '100%' }}
              quality={100}
              objectPosition="left top"
              placeholder="blurred"
              alt="GoGet Community"
            />
          }
          mobileBackgroundImg={
            <StaticImage
              src={GogettersBannerMobile}
              loading="eager"
              style={{ width: '100%' }}
              objectFit="contain"
              placeholder="blurred"
              alt="GoGet Community"
            />
          }
          title={gogettersBannerData.title}
          description={gogettersBannerData.subTitle}
          btn1={{
            link: '/jobs',
            text: 'FIND JOBS'
          }}
          extraMargin
          backgroundMobileHeight="800px"
        />
        <AdvantagesOfGoget data={advantagesOfGoget} />
        <HowToHelpWrapper>
          <HowWeCanHelp />
        </HowToHelpWrapper>
        <JobInProgressWrapper>
          <JobsProgress title="GoGetters in action" />
        </JobInProgressWrapper>
        <MeetOurCommunity data={meetCommunityData} />
        <Banner
          backgroundColor="#ffd601"
          image={
            <StaticImage
              src={DesktopAdImage}
              placeholder="blurred"
              style={{ height: '100%' }}
              alt="Banner image"
            />
          }
          mobileImage={
            <StaticImage
              src={MobileBannerImage}
              placeholder="blurred"
              alt="Banner image"
            />
          }
          title="Be part of something bigger"
          subText={<div>Join our community and get connected.</div>}
          btn1={{ text: 'LEARN MORE', link: '/community', type: 'primary' }}
          black
        />
        <br />
        <HowItWorksBackgroundWrapper>
          <TitleHeaderText
            styledMobile={'margin-bottom: 0;'}
            text="How it works"
          />
          <HowItWorks className="home gogetters" data={howItWorksData} />
        </HowItWorksBackgroundWrapper>
        <div>
          <BusinessGetStarted />
          <DownloadUs
            noPaddingBottom
            text={
              <div>
                <div style={{ marginBottom: 16 }}>Start earning now.</div>
                <div> Download and be a GoGetter today.</div>
              </div>
            }
            image={
              <StaticImage
                src={DownloadUSGG}
                placeholder="blurred"
                alt="GoGetter App"
              />
            }
            appleLink={appleLink}
            googleLink={googleLink}
            appDownloadType="gogetter_app_download"
            pageSectionAnalytics="body"
          />
        </div>
        <FooterBackground>
          <Banner
            backgroundColor="#ffd601"
            image={
              <StaticImage
                src={Merchandise}
                style={{ height: '100%', width: '100%' }}
                objectFit="contain"
                quality={100}
                placeholder="blurred"
                alt=""
              />
            }
            mobileImage={
              <StaticImage
                src={Merchandise}
                style={{ height: '100%', width: '100%' }}
                objectFit="contain"
                quality={100}
                placeholder="blurred"
                alt=""
              />
            }
            title="Get your Exclusive GoGet merchandises here!"
            btn1={{
              text: 'SHOP NOW',
              link: 'https://www.photobook.com.my/goget',
              type: 'primary',
              btnProps: {
                target: '_blank',
                rel: 'noreferrer noopener'
              }
            }}
            black
          />
          <FAQSection data={FaqData} />
        </FooterBackground>
      </Layout>
    </>
  );
};

export default Gogetters;

export const query = graphql`
  query GogettersQuery {
    datoCmsGogetter {
      schemaMarkup
    }
  }
`;
