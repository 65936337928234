import React from 'react';
import styled from 'styled-components/macro';
import { StaticImage } from 'gatsby-plugin-image';

const Image1 = '../../../assets/img/gogetters/howItWorks/Image1.png';
const Image3 = '../../../assets/img/gogetters/howItWorks/Image3.png';
const Image4 = '../../../assets/img/gogetters/howItWorks/Image4.png';

const Content = styled.div`
  @media (max-width: 980px) {
    margin-bottom: 6.27rem;
  }
`;
const Content3 = styled.div`
  @media (max-width: 980px) {
    margin-bottom: 4.54rem;
  }
`;

const howItWorksData = [
  {
    title: 'Download & sign up',
    image: (
      <StaticImage
        src={Image1}
        placeholder="blurred"
        alt="Download & sign up"
      />
    ),
    points: [<Content> Fast and easy to register. </Content>]
  },
  {
    title: 'Start earning instantly',
    image: (
      <StaticImage
        src={Image3}
        placeholder="blurred"
        alt="Start earning instantly"
      />
    ),
    points: [
      <>
        {' '}
        Choose between hourly gig jobs, part-time jobs, contract jobs or even
        full time jobs.{' '}
      </>,
      <Content3> Get attractive bonuses.</Content3>
    ]
  },
  {
    title: 'Grow with us',
    image: (
      <StaticImage src={Image4} placeholder="blurred" alt="Grow with us" />
    ),
    points: [
      <> Attend special trainings and upskill yourself. </>,
      <> Earn badges and get exclusive jobs. </>,
      <> Stay worry-free with savings and insurance benefits. </>
    ]
  }
];

export { howItWorksData };
