import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
// badged
import English from '../../../assets/img/business/members/badges/english.png';
import EliteGogetter from '../../../assets/img/business/members/badges/elite-gogetter.png';
// brands
import AmazinGraze from '../../../assets/img/gogetters/community/brands/amazinGraze.png';
import Beep from '../../../assets/img/gogetters/community/brands/beep.png';
import HappyBunch from '../../../assets/img/gogetters/community/brands/happyBunch.png';
import CakeTogether from '../../../assets/img/gogetters/community/brands/cakeTogether.png';
import BloomThis from '../../../assets/img/gogetters/community/brands/bloomthis.png';
import MLogo from '../../../assets/img/gogetters/community/brands/M.png';
import Pods from '../../../assets/img/gogetters/community/brands/pods.png';
import FashionValet from '../../../assets/img/gogetters/community/brands/fashionValet.png';
import CommonGround from '../../../assets/img/gogetters/community/brands/commonGround.png';
import Mossery from '../../../assets/img/gogetters/community/brands/mossery.png';
import Zalora from '../../../assets/img/gogetters/community/brands/zalora.png';
import GogetBusiness from '../../../assets/img/gogetters/community/brands/goget.png';

import PartyPerfect from '../../../assets/img/gogetters/community/brands/partyPerfect.png';
import CakeTogether1 from '../../../assets/img/gogetters/community/brands/cakeTogether1.png';
import EatCake from '../../../assets/img/gogetters/community/brands/eatCake.png';
import AiTech from '../../../assets/img/gogetters/community/brands/AiTech.png';

// Experiences
const WarehouseHelper =
  '../../../assets/img/business/members/experience/warehouse-helper.png';
const ManningBooth =
  '../../../assets/img/business/members/experience/manning-booth.png';
const Labour = '../../../assets/img/business/members/experience/labour.png';
const FoodShopping =
  '../../../assets/img/business/members/experience/food-shopping.png';
const Dispatch = '../../../assets/img/business/members/experience/disptach.png';
const EventStaff =
  '../../../assets/img/business/members/experience/event-staff.png';
const DataEntry =
  '../../../assets/img/business/members/experience/data-entry.png';
const AdminStaff =
  '../../../assets/img/business/members/experience/admin-staff.png';
const Caller = '../../../assets/img/business/members/experience/caller.png';

// Community
const Member1 = '../../../assets/img/gogetters/community/hanisImage.png';
const Member1Banner = '../../../assets/img/gogetters/community/hanisBanner.png';
const Member2 = '../../../assets/img/gogetters/community/hafixImage.png';
const Member2Banner = '../../../assets/img/gogetters/community/hafixBanner.png';
const Member3 = '../../../assets/img/gogetters/community/sooImage.png';
const Member3Banner = '../../../assets/img/gogetters/community/sooBanner.png';
const Member4 = '../../../assets/img/business/members/4.png';
const Member4Banner =
  '../../../assets/img/gogetters/community/roshanBanner.png';

export const meetCommunityData = [
  {
    ggPage: true,
    photo: <StaticImage src={Member1} placeholder="blurred" alt="Hanis" />,
    banner: (
      <StaticImage
        src={Member1Banner}
        placeholder="blurred"
        style={{ borderRadius: '10px' }}
        alt="Hanis Banner"
      />
    ),
    name: 'Hanis',
    since: 2018,
    job: 'Stay-At-Home Mom',
    rating: '5.00',
    video: 'https://player.vimeo.com/video/450094522?color=00b4ad',
    stats: [
      { head: '364', text: 'Jobs completed' },
      { head: '155', text: 'Total posters helped' }
    ],
    badges: [{ image: EliteGogetter, name: 'Elite GoGetter' }],
    experience: [
      {
        image: (
          <StaticImage
            src={EventStaff}
            placeholder="blurred"
            style={{ width: '100%' }}
            alt="Event Staff"
          />
        ),
        name: 'Event Staff',
        link: `${process.env.GATSBY_PWA_PATH}/part_timer?task=event_crew`
      },
      {
        image: (
          <StaticImage
            src={DataEntry}
            placeholder="blurred"
            style={{ width: '100%' }}
            alt="Data Entry"
          />
        ),
        name: 'Personal Assistant',
        link: `${process.env.GATSBY_PWA_PATH}/personal_helper?task=personal_assistant`
      },
      {
        image: (
          <StaticImage
            src={FoodShopping}
            placeholder="blurred"
            style={{ width: '100%' }}
            alt="Food Shopping"
          />
        ),
        name: 'Food / Shopping',
        link: `${process.env.GATSBY_PWA_PATH}/food_shopping`
      },
      {
        image: (
          <StaticImage
            src={Dispatch}
            placeholder="blurred"
            style={{ width: '100%' }}
            alt="Dispatch"
          />
        ),
        name: 'Dispatch',
        link: `${process.env.GATSBY_PWA_PATH}/dispatch`
      }
    ],
    brands: [
      AmazinGraze,
      Beep,
      HappyBunch,
      CakeTogether,
      BloomThis,
      MLogo,
      Pods
    ]
  },
  {
    ggPage: true,
    photo: <StaticImage src={Member4} placeholder="blurred" alt="Roshan" />,
    banner: (
      <StaticImage
        src={Member4Banner}
        placeholder="blurred"
        style={{ borderRadius: '10px' }}
        alt="Roshan Banner"
      />
    ),
    name: 'Roshan',
    since: 2019,
    job: 'Full Time Student',
    rating: '5.00',
    video: 'https://player.vimeo.com/video/450096690?color=00b4ad',
    stats: [
      { head: '283', text: 'Jobs completed' },
      { head: '53', text: 'Total posters helped' }
    ],
    badges: [{ image: EliteGogetter, name: 'Elite GoGetter' }],
    experience: [
      {
        image: (
          <StaticImage
            src={FoodShopping}
            placeholder="blurred"
            style={{ width: '100%' }}
            alt="Food Shopping"
          />
        ),
        name: 'Food / Shopping',
        link: `${process.env.GATSBY_PWA_PATH}/food_shopping`
      },
      {
        image: (
          <StaticImage
            src={Caller}
            placeholder="blurred"
            style={{ width: '100%' }}
            alt="Caller"
          />
        ),
        name: 'Caller',
        link: `${process.env.GATSBY_PWA_PATH}/part_timer?task=caller`
      },
      {
        image: (
          <StaticImage
            src={ManningBooth}
            placeholder="blurred"
            style={{ width: '100%' }}
            alt="Manning Booth"
          />
        ),
        name: 'Manning Booth',
        link: `${process.env.GATSBY_PWA_PATH}/part_timer?task=manning_booth`
      },
      {
        image: (
          <StaticImage
            src={Dispatch}
            placeholder="blurred"
            style={{ width: '100%' }}
            alt="Dispatch"
          />
        ),
        name: 'Dispatch',
        link: `${process.env.GATSBY_PWA_PATH}/dispatch`
      }
    ],
    brands: [GogetBusiness, Pods]
  },
  {
    ggPage: true,
    photo: <StaticImage src={Member2} placeholder="blurred" alt="Hafizzar" />,
    banner: (
      <StaticImage
        src={Member2Banner}
        placeholder="blurred"
        style={{ borderRadius: '10px' }}
        alt="Hafizzar Banner"
      />
    ),
    name: 'Hafizzar',
    since: 2019,
    job: 'Graphic Designer',
    rating: '4.94',
    video: 'https://player.vimeo.com/video/450096868?color=00b4ad',
    stats: [
      { head: '18', text: 'Jobs completed' },
      { head: '16', text: 'Total posters helped' }
    ],
    badges: [{ image: English, name: 'Skilled in English' }],
    experience: [
      {
        image: (
          <StaticImage
            src={EventStaff}
            placeholder="blurred"
            style={{ width: '100%' }}
            alt="Event Staff"
          />
        ),
        name: 'Event Staff',
        link: `${process.env.GATSBY_PWA_PATH}/part_timer?task=event_crew`
      },
      {
        image: (
          <StaticImage
            src={Labour}
            placeholder="blurred"
            style={{ width: '100%' }}
            alt="Moving Bulky Items"
          />
        ),
        name: 'Moving Bulky Items',
        link: `${process.env.GATSBY_PWA_PATH}/part_timer?task=bulky`
      },
      {
        image: (
          <StaticImage
            src={WarehouseHelper}
            placeholder="blurred"
            style={{ width: '100%' }}
            alt="Packer"
          />
        ),
        name: 'Packer',
        link: `${process.env.GATSBY_PWA_PATH}/part_timer?task=picker_and_packer`
      }
    ],
    brands: [FashionValet, CommonGround, Mossery, Zalora]
  },
  {
    ggPage: true,
    photo: <StaticImage src={Member3} placeholder="blurred" alt="Soo" />,
    banner: (
      <StaticImage
        src={Member3Banner}
        placeholder="blurred"
        style={{ borderRadius: '10px' }}
        alt="Soo Banner"
      />
    ),
    name: 'Soo',
    since: 2018,
    job: 'Retiree',
    rating: '4.98',
    video: 'https://player.vimeo.com/video/450097403?color=00b4ad',
    stats: [
      { head: '338', text: 'Jobs completed' },
      { head: '142', text: 'Total posters helped' }
    ],
    badges: [{ image: EliteGogetter, name: 'Elite GoGetter' }],
    experience: [
      {
        image: (
          <StaticImage
            src={AdminStaff}
            placeholder="blurred"
            style={{ width: '100%' }}
            alt="Admin Staff"
          />
        ),
        name: 'Admin Staff',
        link: `${process.env.GATSBY_PWA_PATH}/part_timer?task=admin_staff`
      },
      {
        image: (
          <StaticImage
            src={DataEntry}
            placeholder="blurred"
            style={{ width: '100%' }}
            alt="Data Entry"
          />
        ),
        name: 'Data Entry',
        link: `${process.env.GATSBY_PWA_PATH}/part_timer?task=data_entry`
      },
      {
        image: (
          <StaticImage
            src={FoodShopping}
            placeholder="blurred"
            style={{ width: '100%' }}
            alt="Food Shopping"
          />
        ),
        name: 'Food / Shopping',
        link: `${process.env.GATSBY_PWA_PATH}/food_shopping`
      },
      {
        image: (
          <StaticImage
            src={Dispatch}
            placeholder="blurred"
            style={{ width: '100%' }}
            alt="Dispatch"
          />
        ),
        name: 'Dispatch',
        link: `${process.env.GATSBY_PWA_PATH}/dispatch`
      }
    ],
    brands: [PartyPerfect, CakeTogether1, EatCake, BloomThis, AiTech]
  }
];
