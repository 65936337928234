import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const AboveAge = './assets/AboveAge.png';
const English = './assets/English.png';
const OnlineBanking = './assets/OnlineBanking.png';
const Smartphone = './assets/Smartphone.png';

const getStartedData = [
  {
    text: '18 years old and above',
    icon: (
      <StaticImage
        src={AboveAge}
        placeholder="blurred"
        style={{ width: '66px', marginRight: '5px' }}
        alt="Above 18 years old"
      />
    )
  },
  {
    text: 'Online banking account',
    icon: (
      <StaticImage
        src={OnlineBanking}
        placeholder="blurred"
        style={{ width: '66px', marginRight: '5px' }}
        alt="Online banking"
      />
    )
  },
  {
    text: 'Smartphone with data connection',
    icon: (
      <StaticImage
        src={Smartphone}
        placeholder="blurred"
        style={{ width: '66px', marginRight: '5px' }}
        alt="Smartphone with data"
      />
    )
  },
  {
    text: 'Good command of English',
    icon: (
      <StaticImage
        src={English}
        placeholder="blurred"
        style={{ width: '66px', marginRight: '5px' }}
        alt="Good command of english"
      />
    )
  }
];

export { getStartedData };
