import React from 'react';
import styled from 'styled-components/macro';

import { getStartedData } from './const/getStartedData';
import GetStarted from '../shared/GetStarted';

const Container = styled.div`
  max-width: 1122px;
  margin: 0 auto;
  width: 90%;
  margin-top: 4rem;
  // @media (max-width: 980px) {
  //   margin-top: 23rem;
  // }
  @media (max-width: 760px) {
    margin-top: 5rem;
  }
`;

const BusinessGetStarted = () => {
  return (
    <Container>
      <GetStarted
        title="Here's what you need to get started"
        data={getStartedData}
      />
    </Container>
  );
};

export default BusinessGetStarted;
